<div class="border-2 rounded-top">
  <div class="p-2 d-flex justify-content-between align-items-center">
    @if (data.title) {
    <h2 class="mx-auto">{{ data.title }}</h2>
    }
    <button class="border-0" (click)="close()" [class.ms-auto]="!data.title">
      <img src="/assets/cross-black.svg" alt="Fermer la fenêtre" />
    </button>
  </div>
  <div class="bottom-sheet-content">
    <ng-container *ngTemplateOutlet="data.template"></ng-container>
  </div>
</div>
